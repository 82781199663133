'use strict';

import axios from 'axios';
import { message } from 'ant-design-vue';
import router from '@/router';
import store from '@/store';
import errorMsg from 'ismartek-error-message';
const http = axios.create({
  baseURL: process.env.VUE_APP_API_SERVER,
});

// 添加请求拦截器
http.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    const { tenant, token } = store.state.account;
    tenant && (config.headers.tenant = tenant);
    token && (config.headers.token = token);
    config.code && (config.headers.code = config.code);
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
http.interceptors.response.use(
  response => {
    // 对响应数据做点什么 headers,
    const { data, headers,  request } = response;
    if (request.responseType === 'blob') {
      return new Promise((resolve, reject) => {
          // console.log('resolve', resolve)
        const reader = new FileReader();
        reader.readAsText(data, 'utf-8');
        reader.onload = ({ target }) => {
          if (target.result.includes('"isError":true')) {
            const result = JSON.parse(target.result);
            // message.error(result.msg);
            if (data.code !== 2001) {
              errorMsg[result.code]
                ? message.error(errorMsg[result.code])
                : message.error(result.msg);
            }
            [2001, 2002, 2003, 2004, 2005].includes(result.code) &&
              router.push('/login');
            // result.code === 2001 && router.push('/login');
            return reject(response);
          }
          return resolve({
            name: decodeURI(
              headers['content-disposition'].split(';')[1].split('fileName=')[1]
            ),
            data,
          });
        };
      });
    } else {
      if (data.isError) {
        if (data.code !== 2001) {
          errorMsg[data.code]
            ? message.error(errorMsg[data.code])
            : message.error(data.msg);
        }
        // 会话超时，请重新登录
        [2001, 2002, 2003, 2004, 2005].includes(data.code) &&
          router.push('/login');
        // data.code === 2001 && router.push('/login');
        return Promise.reject(response);
      }
      return Object.prototype.hasOwnProperty.call(data, 'data')
        ? data.data
        : data;
    }
  },
  error => {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

export default http;
