'use strict';

import http from '@/utils/http';
import axios from 'axios';

export default {
  getPage(code, params) {
    return http.get('/authority/user/page', { params, code });
  },
  add(code, data) {
    return http.post('/authority/user', data, { code });
  },
  update(code, data) {
    return http.put('/authority/user', data, { code });
  },
  delete(code, params) {
    return http.delete('/authority/user', { params, code });
  },
  avatar(code, data) {
    return http.put('/authority/user/avatar', data, { code });
  },
  getUserIdByRoleId(code, roleId) {
    return http.get(`/authority/role/user/${roleId}`, { code });
  },
  async getMenus() {
    // if (process.env.NODE_ENV === 'development') {
      const { data } = await axios.get(
        `${process.env.VUE_APP_PUBLIC_PATH}` +
          'router.json' +
          `?time=${+new Date()}`
      );
      return data.map(item => {
        if (item.children) {
          return {
            ...item,
            name: item.menuKey,
            children: item.children.map(i => ({
              ...i,
              name: i.menuKey,
            })),
          };
        }
        return { ...item, name: item.menuKey };
      });
    // } else {
    //   return http.get('/authority/menu/router', { params }).then(result =>
    //     result.map(item => {
    //       if (item.children) {
    //         return {
    //           ...item,
    //           name: item.menuKey,
    //           children: item.children.map(i => ({
    //             ...i,
    //             name: i.menuKey,
    //           })),
    //         };
    //       }
    //       return { ...item, name: item.menuKey };
    //     })
    //   );
    // }
  },

  async getApps() {
    // if (process.env.NODE_ENV === 'development') {
      const { data } = await axios.get(
        `${process.env.VUE_APP_PUBLIC_PATH}` +
          'link.json' +
          `?time=${+new Date()}`
      );
      return data;
    // } else {
    //   return http.get(`/authority/applicationClient/visible/${userId}`);
    // }
  },


//   用户管理接口
  pageUrl: '/connections_treasure_box_admin_backend/sys-user/getSysUserList',
  // 渠道来源
  treeList: '/connections_treasure_box_admin_backend/dictionary-item/code/tree',
  // 用户详情-邀请用户分页
  getOrderUserList: '/connections_treasure_box_admin_backend/sys-user/getOrderUserList',
  // 用户-订阅类型
  getPackageInfoTypeDetailList :'/connections_treasure_box_admin_backend/payment/package/getPackageInfoTypeDetailList',
  queryOrderUserList: '/connections_treasure_box_admin_backend/user/orderActivity/queryOrderUserList',
  
  
  // 导出用户列表数据
  exportSysUserList(code, params) {
    return http.get('/connections_treasure_box_admin_backend/sys-user/exportSysUserList', {
      responseType: 'blob',
      code,
      params,
    });
  },
  // 导出用户邀请列表数据个人详情
  exportOrderUserList(code, params) {
    return http.get('/connections_treasure_box_admin_backend/sys-user/exportOrderUserList', {
      responseType: 'blob',
      code,
      params,
    });
  },

  // 查询用户模块表头信息
  getUserDataBoard(code, data) {
    return http.post('/connections_treasure_box_admin_backend/sys-user/getUserDataBoard', data, { code });
  },
//   根据用户Id 查询用户详情数据
  getUserInfoById(code, id) {
    return http.get(`/connections_treasure_box_admin_backend/sys-user/getUserInfoById/${id}`, { code });
  },

  // 查询当前用户使用情况
  getUsageDetails(code, id) {
    return http.get(`/connections_treasure_box_admin_backend/contact/contact-todo-matter/getUsageDetails/${id}`, { code });
  },

  //  排行榜新增
  saveActivity(code, data) {
    return http.post('/connections_treasure_box_admin_backend/user/orderActivity/saveActivity', data, { code });
  },

  //  排行榜编辑
  updateOrderActivity(code, data) {
    return http.put('/connections_treasure_box_admin_backend/user/orderActivity/updateOrderActivity', data, { code });
  },
//  排行榜删除
deleteById(code, id) {
    return http.delete(
      `/connections_treasure_box_admin_backend/user/orderActivity/deleteById/${id}`,
      { code }
    );
  },

  //  兑换修改保存按钮
  exchangeSendRecord(code, data) {
    return http.put('/connections_treasure_box_admin_backend/user/exchange-send-record', data, { code });
  },

};
