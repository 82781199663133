'use strict';

export default {
  namespaced: true,
  state: {
    enums: null,
    apps: null,
    breadcrumbs: null,
    loading: true,
  },
  mutations: {
    setEnums(state, value) {
      state.enums = value;
    },
    setApps(state, value) {
      state.apps = value;
    },
    setBreadcrumbs(state, value) {
      state.breadcrumbs = value;
    },
    setLoading(state, value) {
      state.loading = value;
    },
  },
};
