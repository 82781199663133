<template>
  <div class="iss-header">
    <div>
      <iss-breadcrumb />
    </div>
    <div class="header-right">
      <!-- <iss-search class="right-item" /> -->
      <div class="right-item"><iss-fullscreen /></div>
      <!-- <div class="right-item"><iss-language /></div> -->
      <div class="right-item"><iss-user /></div>
    </div>
  </div>
</template>

<script>
// import IssSearch from './search';
import IssFullscreen from './fullscreen';
// import IssLanguage from './language';
import IssUser from './user';
import IssBreadcrumb from '../breadcrumb';

export default {
  components: {
    // IssSearch,
    IssFullscreen,
    // IssLanguage,
    IssUser,
    IssBreadcrumb,
  },
};
</script>

<style lang="less" scoped>
.iss-header {
  display: flex;
  justify-content: space-between;
  .header-right {
    display: flex;
    font-size: 20px;
    color: rgba(49, 61, 95, 0.7);
    line-height: 56px;
    padding-right: 16px;
    align-items: center;
    .right-item {
      padding: 0 5px;
      cursor: pointer;
      &:hover {
        background-color: #eceef1;
      }
    }
  }
}
</style>
