<template>
  <div class="iss-menu-wrapper">
    <router-link to="/" class="menu-logo">
      <img :src="logoUrl" alt="首页" style="width: 100%" />
    </router-link>
    <a-menu
      class="iss-menu"
      mode="inline"
      theme="light"
      :openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
      @openChange="handleOpenChange"
      @click="handleClick"
    >
      <template v-for="item in menuList" :key="item.path">
        <a-sub-menu
          v-if="item.children"
          :key="item.path"
          :title="item.meta.title"
        >
          <template #icon>
            <component :is="icons[item.meta.icon]" />
          </template>

          <a-menu-item
            v-for="child in item.children"
            :key="child.path"
            :accessWay="child.accessWay"
            :link="child.component"
          >
            {{ child.meta.title }}
          </a-menu-item>
        </a-sub-menu>
        <a-menu-item v-else :key="item.path">
          <template #icon>
            <component :is="icons[item.meta.icon]" />
          </template>
          {{ item.meta.title }}
        </a-menu-item>
      </template>
    </a-menu>
  </div>
</template>

<script>
import { computed, reactive, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { Menu, Tooltip } from 'ant-design-vue';
import * as icons from '@ant-design/icons-vue';

export default {
  components: {
    ATooltip: Tooltip,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    ASubMenu: Menu.SubMenu,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const pathArr = route.path.split('/');
    const state = reactive({
      openKeys: [`/${pathArr[1]}`],
      selectedKeys: [
        pathArr.length > 4 ? pathArr.slice(0, 4).join('/') : route.path,
      ],
    });
    const menuList = computed(() => store.state.account.menus);

    // 全员营销跳转
    watch(route, val => {
      if (val.path === '/system/enterprise') {
        state.openKeys = ['/system'];
        state.selectedKeys = ['/system/enterprise'];
      }
    });

    return {
      logoUrl: process.env.VUE_APP_PROJECT_LOGO,
      icons,
      menuList,
      ...toRefs(state),
      handleOpenChange(openKeys) {
        state.openKeys = [openKeys[1]];
      },
      handleClick({ item, key }) {
        switch (item.accessWay) {
          case 'link':
            window.open(item.link);
            break;
          case 'iframe':
            router.push(`${key}?path=${encodeURIComponent(item.link)}`);
            break;
          default:
            state.selectedKeys[0] !== key &&
              store.commit('common/setLoading', true);
            router.push(key);
            break;
        }
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-menu-wrapper {
  padding-top: 16px;
  // @primary-color
  background-color: @layout-header-background;
  .menu-logo {
    display: block;
    margin: 0 20px 0px 20px;
  }
  .iss-menu.ant-menu-inline {
    margin-top: 20px;
    height: calc(100vh - 75px);
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0px;
    }
    :deep(.ant-menu-item-selected) {
      background: #f0f5ff;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 4px;
        background-color: @primary-7;
        border-right: 0px;
      }
    }
  }
}
.iss-menu-wrapper /deep/ .ant-menu-sub.ant-menu-inline {
  background: #fff;
}
.iss-menu-wrapper /deep/ .ant-menu-inline {
  overflow-x: hidden;
}
</style>
